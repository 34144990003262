import React from "react"

import Layout from "../components/flayout"
import Contactus from "../components/contactUs"

const contactUs = () => (
  <Layout darkHeader={true} seoFields={seoFields}>  
    <Contactus />
  </Layout>
)

const seoFields = {
  description: "Contact MProfit via phone, email or live chat if you have any questions. Our customer support team is highly rated for providing world-class assistance to clients in making management of investment portfolios simple, efficient, accurate & insightful.",
  // lang,
  // meta,
  // keywords,
  title: "Contact Us" 
}

export default contactUs
