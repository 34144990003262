import React from "react"

import styles from "./index.module.css";
import  attachment_icon  from "../../images/attachment_icon.svg";
import { Link } from "gatsby";

class Contact extends  React.Component{
    
    constructor(props)
    {
        super(props);
        this.state = {
            name: "",
            email: "",
            subject: "",
            message: "",
            file: "",
            formErrors: { email: '', message: ''},
            emailValid: true,
            nameValidation: false,
            messageValidation: false,
        }
    }
    
    changeHnadler = (event) => {
        const target = event.target;
        const value =  target.value;
        const name = target.name;
        this.setState({
            [name] : value
        },() => { this.validateField(name, value) });
    }

    validateField(fieldName, value) {
        let fieldValidationErrors = this.state.formErrors;
        let emailValid = this.state.emailValid;
    
      
        switch(fieldName) {
          case 'email':
            emailValid = value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
            fieldValidationErrors.email = emailValid ? false : true;
            break;
          
          default:
            break;
        }
        this.setState({formErrors: fieldValidationErrors,
                        emailValid: emailValid,
                      });
    }

    openFreshworksWidget = () => {
        try {
            window.FreshworksWidget('open');
        } catch {

        }
    }
      
 
    render(){
        return(
            <>
                <div className={styles.contact_main_section}>
                    <div className={[styles.top_banner_section,'d-flex','justify-content-center'].join(' ')}>
                        <div className={['align-self-end', 'w-100'].join(' ')}>
                            <div className="align-self-stretch">
                                <div className="container">
                                    <div className={["row", "justify-content-md-center"].join(" ")}>
                                        <div className={[styles.mpr_main_title ,'col-md-10', 'mb-5', 'pb-5'].join(' ')}>
                                            <h2>Contact us</h2>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={styles.contact_page_section}>
                        <div className="container">
                            <div className={["row", "justify-content-md-center"].join(" ")}>
                                <div className="col-md-10">
                                    <div className="row">
                                        <div className={["col-md-7", "mb-md-0", "mb-4"].join(" ")}>
                                            <h2 className="mb-2">Request a callback</h2>
                                            <p className="mb-4"><a onClick={this.openFreshworksWidget} className={styles.blueLink}>Click here to request a callback</a> during our business hours</p>
                                            
                                            <p><strong className="d-block">Monday through Friday</strong>
                                            10:00 am to 5:30 pm (IST)</p>

                                            <p><strong className="d-block">Saturday</strong>
                                            10:00 am to 1:30 pm (IST)</p>

                                            <h2 className="mt-5 mb-4">Head Office</h2>

                                            <p>
                                                <strong className="d-block">MProfit Software Private Limited</strong>
                                                108-B Mittal Tower, 210 Nariman Point <br/>
                                                Mumbai-400021, Maharashtra, India <br/>
                                                Contact: +91 2240057250
                                            </p>

                                        </div>
                                        <div className="col-md-5">
                                            <h2 className="mb-2">Email us anytime</h2>
                                            <p className="mb-4">Write to us at <a href="mailto:support@mprofit.in" className={styles.blueLink}>support@mprofit.in</a></p>

                                            <h2 className="mt-5 mb-2">MProfit Help Center</h2>

                                            <p className="mb-5"><a href="https://help.mprofit.in" className={styles.blueLink} target="_blank">Click here to view MProfit tutorials</a></p>


                                            <h2 className="mb-4">Remote Support</h2>
                                            <p className="mb-0"><a className={styles.blueLink} href="https://files.mprofit.in/import/tv.exe">Download TeamViewer 8 for Windows</a></p>
                                            <p className="mb-0"><a className={styles.blueLink} href="https://download.teamviewer.com/download/version_8x/TeamViewer.dmg">Download TeamViewer 8 for Mac OS X</a></p>
                                            <p className="mb-0"><a className={styles.blueLink} href="https://anydesk.com/en/downloads" target="_blank">Download AnyDesk</a></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

export default Contact
